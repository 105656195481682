<template>
  <div class="good-detail">
    <div class="topbg">
      <div class="icon df aic jcsb p20 fz34" style="position: relative;z-index: 888;">
        <van-icon name="arrow-left" @click="goback" size="30"/>
        <div class="df aic">
          <van-icon name="star-o" />
          <van-icon name="share-o" />
        </div>
      </div>
    <div class="shop-home-img">
      <img :src="this.shopdata.shop_home_img" style="width: 100%;position: absolute;top: 0;">
    </div>
    </div>
    <div class="content p40 df fdc bsbb">
      <p class="fz40 fw7 mb20" style="color: #000">
       {{ trade_name }}
      </p>
     <!-- <p class="fz22 mb10 fw7" style="color: rgba(56, 173, 61, 1)">
        近2个月160人买过
      </p>
      <p class="fz26 mb5" style="color: #999">月售756</p> -->
      <div class="price df aic jcsb fz16">
        <p style="color: red"> <span class="fz40 fw7">{{ this.buy_actual_amount }}</span></p>
       <!-- <van-button class="fz20" @click="goPerchase">+添加订单</van-button> -->
      </div>
    </div>
   <!-- <div class="redbag p20 fz24 df fdc bsbb" style="color: #999">
      <div class="df aic jcsb mb20">
        <p class="mr20">红包</p>
        <div class="over df aic">
          <img src="../../assets/images/xg/59-20.png" />
          <img src="../../assets/images/xg/59-20.png" />
        </div>
        <div class="df aic">
          <p>7个红包</p>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="df aic jcsb">
        <div class="df aic jcsb">
          <p class="mr40">保障</p>
          <p style="color: #666" class="fw7">不支持七天无理由退款</p>
        </div>
        <van-icon name="arrow" />
      </div>
    </div> -->
    <div class="tabs p20 mt20">
      <van-tabs v-model:active="active" background="#f2f2f2" shrink line-width="20" color="#55bb49" line-height="5">
        <van-tab title="詳情">
          <div class="de">
            <div class="detail p20 bsbb df fdc">
              <p class="fz30 fw7">圖文詳情</p>
              <div class="df fdc p40">
                <img :src="this.shopdata.shop_rotation_img"  style="width: 100%;"/>
              </div>
            </div>
           <!-- <div class="commend1 p20 bsbb df aic jcsb fz36 fw7">
              <p>商品评价</p>
              <van-icon name="arrow" />
            </div> -->
          </div>
        </van-tab>
        <!-- <van-tab title="评价">222</van-tab> -->
      </van-tabs>
    </div>
    <div class="footer df aic jcsb bsbb">
      <!-- <div class="price df aic jcc fz16">
        配送费满 <span class="red"> 0.01减5</span>全店红包满<span class="red">59减20</span>、满<span class="red">89减30</span>
        …
      </div> -->
      <!-- <div class="count df aic jcc">
        <div class="totalCount">
          <img src="../../assets/images/xg/shopcar1.png" class="mr20" />
          <i class="font-size-16" v-if="isSelect != 0">{{ isSelect }}</i>
        </div>
        <div class="df fdc">
          <p>
            <b style="color: red" class="font-size-36 mr20">¥{{ totalPrice }}</b>
            <span style="color: red" class="font-size-16"></span> -->
      <!-- <span class="font-size-16"
              >优惠明细<van-icon name="arrow-up"
            /></span> -->
      <!-- </p>
          <p class="font-size-16">预估加配送费¥4.5</p>
        </div>
      </div>
      <div>
        <van-button :disabled="isDisabled" round :type="type" @click="goPay" class="font-size-36 fw7">¥20起送</van-button>
      </div> -->
      <footer class="df aic bsbb jcsb">
        <div class="left df fdc bsbb">
          <p style="color: #fff" class="fz36 mt10">{{ this.buy_actual_amount }}</p>
          <!-- <p class="fz14" style="color: #666">已优惠¥24.4</p> -->
        </div>
        <div class="right df aic jcc fz40" @click="goPay">付款</div>
      </footer>
    </div>
  </div>
</template>

<script>
import { getGoodsList, createOrderReq, commodityDetail } from "../../utils/api";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      active: 0,
      disabled: "",
      type: "success",
      merchant_settlement_id: this.$route.query.id,
      buy_actual_amount: this.$route.query.buy_actual_amount,
      trade_name: this.$route.query.trade_name,
      shop_home_img: '',
      buy_quantity: this.$route.query.buy_quantity,
      shopdata: [],
      order_no: '',
      bannerPic: [],
      shopimg: [],
      shopone: [],
    };
  },
  computed: {
    // isSelect: {
    //   get() {
    //     return this.goodsList.reduce((total, cur) => {
    //       return total + cur.buy_quantity;
    //     }, 0);
    //   },
    //   set() {},
    // },
    // totalPrice: {
    //   get() {
    //     let all = 0;
    //     all = this.goodsList.reduce((total, cur) => {
    //       return total + cur.trade_price * cur.buy_quantity;
    //     }, 0);
    //     return all.toFixed(2);
    //   },
    // },
    // detailList: {
    //   get() {
    //     let orderList = [];
    //     orderList.push(this.shopdata);
    //     return orderList;
    //   },
    // },
    ...mapState("goods", ["merchantList"]),
    ...mapGetters("goods", [
      "getMerchantList",
      "goodsShopList",
      "getMerchantList",
    ]),
  },
  methods: {
    ...mapActions("goods", ["A_getMerchantList"]),
    ...mapMutations("goods", ["SET_GOODSSHOPLIST", "SET_RESET"]),
    // 监听商品修改事件
    handleGoods(num, item) {
      this.SET_GOODSSHOPLIST({ ...item, num });
    },
    goback() {
      this.$router.go(-1);
    },
    opsd() {
      if (this.$route.query.judge == '/home') {
        this.shopone = [{ id: this.shopdata.id, quantity: 1 }]
      } else {
        this.shopone = this.getMerchantList.map((item) => ({
          id: item.id,
          quantity: item.num,
        }))
      }
    },
    goPay() {
      this.opsd()
      createOrderReq({
        commodity: this.shopone,
        payment_method: 10,
      }).then(async (res) => {
        if (res.code === 200) {
          console.log(res, '===');
          this.buy_actual_amount = res.data.buy_actual_amount
          this.order_no = res.data.order_no;
          await this.$router.push({
            name: "detail",
            query: {
              order_no: this.order_no,
              buy_actual_amount: this.buy_actual_amount,
              judge: this.$route.query.judge,
            },
          });
        }
      });
    },
    goPerchase() {
      this.opsd()
      createOrderReq({
        commodity: this.shopone,
        payment_method: 10,
      }).then((res) => {
        if (res.code == 200) {
          this.$toast.success({
            message: "添加订单成功",
            icon: 'success',
            className: 'noticeWidth',
          });
          console.log(res);
        }
      });
    },
  },
  created() {
    // console.log(this.merchant_settlement_id, this.buy_actual_amount);
    // createOrderReq({
    //   commodity: this.shopones,
    //   payment_method: 10,
    // }).then((res) => {
    //   if (res.code == 200) {
    //     console.log(res, 'aaa');
    //     this.order_no = res.data.order_no
    //   }
    // });
    commodityDetail({
      id: this.merchant_settlement_id
    }).then((res) => {
      if (res.code == 200) {
        console.log(res, '```');
        this.shopdata = res.data
        this.shopdata.buy_quantity = 1
        console.log(this.shopdata, '7788');
        console.log(this.shopdata.length);
        let orderList = [];
        orderList.push(this.shopdata);
        localStorage.setItem("orderList", JSON.stringify(orderList));
        // console.log(localStorage.getItem("orderList"), '.....');
        // this.bannerPic = JSON.parse(res.data.shop_rotation_img)
        this.shopimg = JSON.parse(res.data.detail_img)
        this.handleGoods(1, this.shopdata)
      } else {
        this.$toast({
          message: "获取商品信息失败",
          icon: 'fail',
          className: 'noticeWidth',
        });
      }

    })
    // this.opsd()
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tab__text {
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 30px;
  font-family: "pingfang";
}

::v-deep .van-tab--shrink {
  height: 60px;
  margin-right: 62px;
}

.good-detail {
  background: #f2f2f2;
  height: 100%;
  font-family: "pingfang";

  .topbg {
    // background: url("../../assets/images/xg/detail.png") no-repeat center;
    background-size: cover;
    width: 750px;
    height: 714px;

    .swiper {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 714px;
    }

    .icon {
      .van-icon {}
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 150px;
    width: 100%;
    padding: 0 30px;

    .van-button {
      width: 200px;
      padding: 30px;
      box-sizing: border-box;
    }

    .left {
      width: 490px;
      height: 120px;
      background-color: #000;
      border-radius: 60px 0 0 60px;
      padding: 25px 40px;
    }

    .right {
      width: 200px;
      height: 120px;
      background: rgba(55, 159, 0, 1);
      border-radius: 0 60px 60px 0;
      color: #fff;
    }
  }

  .content {
    background: #fff;
    width: 690px;
    margin: 20px auto;
    height: 300px;
    border-radius: 20px;

    .price {
      .van-button {
        width: 180px;
        height: 60px;
        border-radius: 40px;
        background: linear-gradient(90deg,
            rgba(151, 202, 114, 1) 0%,
            rgba(89, 194, 103, 1) 100%);
        color: #fff;
      }
    }
  }

  .redbag {
    margin: 0 auto;
    width: 690px;
    height: 164px;
    background: #fff;
    border-radius: 20px;

    .over {
      overflow-x: scroll;

      img {
        width: 210px;
        margin-right: 20px;
      }
    }
  }

  .tabs {
    height: 2430px;
    background: #f2f2f2;

    .de {
      margin-top: 30px;

      .detail {
        width: 690px;
        height: 2100px;
        background: #fff;
        border-radius: 20px;
        margin: 20px auto;
      }

      .commend1 {
        margin: 20px auto;
        width: 690px;
        height: 100px;
        background: #fff;
        border-radius: 20px;
      }
    }
  }
}

.footer {
  width: 100%;
  background: #fff;
  height: 150px;
  box-sizing: border-box;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  .count {
    .totalCount {
      position: relative;

      img {
        width: 82.25px;
        height: 82.25px;
        opacity: 1;
        margin-top: 30px;
      }

      i {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: red;
        color: #fff;
        position: absolute;
        top: 20px;
        right: 15px;
        border-radius: 50%;
        text-align: center;
      }
    }
  }

  .van-button {
    width: 245px;
    height: 94px;
    border-radius: 56.5px;
  }
}
</style>
<style lang="scss">
.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}
</style>
